import * as React from 'react'
import { withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'

import { routes } from '$src/routes'
import LogoutLink from '$src/user/components/LogoutLink'

import * as NavigationComponent from '$component-library-dist/Navigation-3.umd.js'
import LoginButton from '$src/user/components/LoginButton'

const LOGO_URL =
  'https://cdn.a-lehdet.fi/production/platta/images/a-lehdet_logo_symbol_black.svg'
const NAVIGATION_ITEMS = [
  {
    url: 'https://www.a-lehdet.fi',
    label: 'Etusivu'
  },
  {
    url: '/',
    label: 'Asiakaspalvelu',
    items: [
      {
        url: routes.dashboard(),
        label: 'Omat tiedot',
        restricted: false
      },
      {
        url: routes.subscriptions(),
        label: 'Omat tilaukset',
        restricted: true
      },
      {
        url: routes.billing(),
        label: 'Laskutus',
        restricted: true
      },
      {
        url: routes.customerBenefits(),
        label: 'Asiakasedut',
        restricted: true
      },
      {
        url: routes.giftcards(),
        label: 'Lahjakortti',
        restricted: true
      },
      {
        url: routes.raffles(),
        label: 'Arvonnat',
        restricted: false
      },
      {
        url: routes.subscribers(),
        label: 'Tilaajille',
        restricted: false
      },
      {
        url: routes.chatInfo(),
        label: 'Chat',
        restricted: false
      }
    ]
  },
  {
    label: 'Tilaa lehti',
    url: 'https://lehtitilaukset.a-lehdet.fi'
  },
  {
    label: 'Digilehdet',
    url: 'https://luettavaa.a-lehdet.fi/lue-lehti'
  },
  {
    label: 'Mediaratkaisut',
    url: 'https://www.a-lehdet.fi/aiheet/mediaratkaisut'
  },
  {
    label: 'Työpaikat',
    url: 'https://www.a-lehdet.fi/aiheet/tyopaikat'
  },
  {
    label: 'Konserni',
    url: 'https://www.a-lehdet.fi/aiheet/konserni'
  },
  {
    label: 'Ajankohtaista',
    url: 'https://www.a-lehdet.fi/teemat/ajankohtaista'
  }
]

type Props = {
  isMobile: boolean
  isLoggedIn: boolean
  hasCustomerNumber: boolean
  history: any
  location: any
  match: any
}

const Navigation = ({
  isMobile = false,
  isLoggedIn = false,
  hasCustomerNumber = false,
  location
}) => {
  const breakpoint = isMobile ? 'mobile' : 'desktop'
  const baseDomain = 'www.a-lehdet.fi'
  const path = location.pathname

  // key is used as a workaround to ensure that the component re-renders when
  // login and customer number status are determined.
  const key = `navigation-${isLoggedIn}-${hasCustomerNumber}`

  const items = NAVIGATION_ITEMS.map((item) => {
    if (isLoggedIn && hasCustomerNumber) {
      return item
    }
    if (!item.items) {
      return item
    }
    return {
      ...item,
      items: item.items.filter((subItem) => !subItem.restricted)
    }
  })
  return (
    <>
      <nav>
        <NavigationComponent
          configuration={{
            baseDomain
          }}
          key={key}
          theme="alehdet_fi"
          breakpoint={breakpoint}
          logoUrl={LOGO_URL}
          items={items}
          path={path}
          extensions={[
            <LoginButton
              key="extension-2"
              breakpoint={breakpoint}
              theme="alehdet_fi"
            />,
            <LogoutLink key="extension-1" />
          ]}
          Link={({ href, children, onClick, hostname }) => {
            // This is a workaround for the logo link in which the href is `/`
            if (href === '/' && hostname) {
              href = `https://${hostname}`
            }

            // Only use `NavLink` for relative routes since it doesn't work well
            // with external urls.
            if (href && href.charAt(0) === '/') {
              return (
                <NavLink to={href} onClick={onClick}>
                  {children}
                </NavLink>
              )
            }
            return (
              <a href={href} onClick={onClick}>
                {children}
              </a>
            )
          }}
        />
      </nav>
    </>
  )
}

export default withRouter<Props>(Navigation)
